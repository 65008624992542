.tabs-signature.nav-tabs .nav-item {
  color: white;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white !important;
  background-color: #1d78ff !important;
  border-color: #dee2e6 #dee2e6 #fff;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3 !important;
}

.dowload-document:hover {
  text-decoration: none;
}
